import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Tooltip,
    Chip,
    Button
} from '@mui/material';

import {
    Event as EventIcon,
    Computer as ComputerIcon,
    Chat as ChatIcon,
    Person
} from '@mui/icons-material';

import {
    is_agent,
    canWriteSensitiveData,
    USER_ID
} from "../../../../../Services/permissions";

import {
    requestWithBody,
    handleRequestError
} from "../../../../utils";

import Alert from "../../../Chavelusa/Alert";

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import FormNotes from "../../../Chavelusa/FormNotes";
import ClientInteractionForm from "../Form";

const ClientInteractionWindow = ({ object, clientAgent, updateElement, windowChanged }) => {

    console.log("COMPONENT ClientInteractionWindow", object);

    // Operations
    const [formOpen, setFormOpen] = useState(false);

    const [alert, setAlert] = useState(undefined);
    const [loadingRequest, setLoadingRequest] = useState(false);
    const changeState = (accomplished) => {
        setLoadingRequest(true);
        console.log("CHANGING INTERACTION STATE TO accomplished", accomplished);

        requestWithBody(
            'PATCH',
            process.env.REACT_APP_API_URL + '/crm/interactions/' + object.id + '/?clientAgent=' + clientAgent.id,
            { accomplished: accomplished }
        ).then(response => {
            updateElement(response);
        }).catch(error =>
            handleRequestError(
                error,
                [],
                "Error editing client interaction state!",
            ).then(() => setAlert({ error: true }))
        ).finally(() => {
            setLoadingRequest(false);
        });
    }

    // Static variables
    const ALERT_PROCESS = {
        title: 'Tem a certeza?',
        text: 'Ao marcar como concluído, deixará de ver o alerta para este cliente.',
        action: () => changeState(true)
    };
    const ALERT_UNPROCESS = {
        title: 'Tem a certeza?',
        text: 'Ao reverter a conclusão, passará a ver o alerta para este cliente.',
        action: () => changeState(false)
    };

    return (
        <Grid sx={{ width: '100%' }}>
            <Typography variant="h6" mb={0}>Interação</Typography>
            <Typography variant="caption" mb={0}>
                Última alteração a {new Date(object.last_changed).toLocaleDateString('pt-PT', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
            </Typography>

            { /* State */}
            <Grid xs={12}>
                {
                    !object.accomplished && !object.future
                        ?
                        <Chip
                            label="Interação pendente"
                            icon={<ChatIcon fontSize="small" />}
                            color={(object.owner===USER_ID ? "error" : "warning")}
                            sx={{ my: 3 }}
                        />
                        : object.accomplished ?
                        <Chip
                            label="Interação concluída"
                            icon={<ChatIcon fontSize="small" />}
                            color="primary"
                            variant="outlined"
                            sx={{ my: 3 }}
                        />
                        : <></>
                }
            </Grid>

            { /* Operations */}
            {
                !clientAgent.archived &&
                <Grid mb={3} xs={12} container direction='row'>
                    {
                        !clientAgent.archived && (is_agent || canWriteSensitiveData) &&
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => setFormOpen(true)}
                            sx={{ mr: 2, mt: 1 }}
                        >Editar</Button>
                    }
                    {
                        object.ownerData.user.id === USER_ID
                        && 
                        (
                            !loadingRequest
                            ?
                            (
                                !object.accomplished
                                    ?
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => setAlert(ALERT_PROCESS)}
                                        sx={{ mr: 2, mt: 1 }}
                                    >
                                        Marcar concluído
                                    </Button>
                                    :
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => setAlert(ALERT_UNPROCESS)}
                                        sx={{ mr: 2, mt: 1 }}
                                    >
                                        Marcar como pendente
                                    </Button>
                            )
                            :
                            <LoadingButton
                                loading
                                color="primary"
                                variant="contained"
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                sx={{ mr: 2, mt: 1 }}
                            >
                                {!object.accomplished ? "Marcar concluído" : "Marcar como pendente"}
                            </LoadingButton>
                        )
                    }
                </Grid>
            }

            { /* Details */}
            <Grid my={3}>
                <Typography>
                    <Tooltip title="Responsável">
                        <Person color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                    </Tooltip>
                    {object.ownerData.user.name}
                </Typography>
                <Typography>
                    <Tooltip title="Momento">
                        <EventIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                    </Tooltip>
                    {new Date(object.moment).toLocaleDateString('pt-PT', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
                </Typography>
            </Grid>

            { /* Report */}
            <FormNotes
                noteOriginal={object.report}
                url={process.env.REACT_APP_API_URL + '/crm/interactions/' + object.id + '/?clientAgent=' + clientAgent.id}
                attribute={'report'}
                onUpdate={updateElement}
                formChanged={windowChanged}
                title={"notas"}
                deactivated={clientAgent.archived || !(is_agent || canWriteSensitiveData)}
            />

            { /* Operations confirmation box */}
            {
                alert &&
                <Alert
                    error={alert.error}
                    title={alert.title}
                    text={alert.text}
                    action={alert.action}
                    close={() => setAlert(undefined)}
                />
            }

            {/** Forms */}
            {
                formOpen && 
                <ClientInteractionForm
                    object={object}
                    clientAgentId={clientAgent.id}
                    onUpdate={updateElement} 
                    destroy={() => setFormOpen(false)}
                />
            }
        </Grid>
    );
}

export default ClientInteractionWindow;