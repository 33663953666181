import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Avatar,
    Badge,
    Link,
    Tooltip
} from '@mui/material';

import {
    Event as EventIcon,
    StickyNote2 as StickyNote2Icon,
    Summarize as SummarizeIcon,
    Edit as EditIcon
} from '@mui/icons-material';

import ChaveLusaDialog from "../../../Chavelusa/Dialog";
import FormGroup from "../../../Chavelusa/FormGroup";
import Alert from "../../../Chavelusa/Alert";

import {
    requestWithBody,
    handleRequestError,
    requestGET
} from '../../../../utils';

import {
    buildFilterDateTime,
    buildFilterSelect,
    buildFilterText,
} from '../../../Chavelusa/FormGroup/utils';

const fieldsGeneral = [
    buildFilterDateTime('Momento', 'moment', true, true, true),
    buildFilterSelect("Responsável", "select", "owner", [], null, true),
];

/**
 * ClientInteractionForm component
 * 
 * @param {crm.models.ClientClassification} classification
 * @param {Integer} clientAgentId
 * @param {Method} onUpdate Called when classification updated with the server response as argument
 * @param {Method} destroy Called when operations finished (submitted or canceled)
 */

const ClientInteractionForm = ({ object, clientAgentId, onUpdate, destroy }) => {

    console.log("ClientInteractionForm", object);

    const url = process.env.REACT_APP_API_URL + '/crm/interactions/' + (object ? (object.id + '/?clientAgent=' + clientAgentId) : '');

    // Fields
    const [fields, setFields] = useState(fieldsGeneral);
    const loadingMonitor = [true];
        const [loading, setLoading] = useState(loadingMonitor.some(l => l));
    useEffect(() => {
        requestGET(process.env.REACT_APP_API_URL + '/login/users/?clientAgent='+clientAgentId).then(
            response => {
                console.log("Got /login/users/", response);
                setFields(f => {
                    f[1]['values'] = response.map(r => {return {label: r.user.name, id: r.user.id};});
                    return f;
                });
                loadingMonitor[0] = false;
                setLoading(loadingMonitor.some(l => l));
            }
        );
    }, []);
    

    // API Calls
    const [error, setError] = useState(undefined);
    const [submitting, setSubmitting] = useState(false);
    const submitForm = (data) => {
        setSubmitting(true);

        requestWithBody(
            object ? "PATCH" : "POST",
            url,
            {...data, clientAgent: clientAgentId}
        ).then(response => {
            console.log(
                "Interaction updated with success",
                response
            );
            onUpdate(response);
        }).catch(error =>
            handleRequestError(
                error,
                [],
                "Error updating interaction"
            )
            .then(e => setError(e))
        ).finally(() => {
            setSubmitting(false);
        });
    }

    // Render
    return (
        <ChaveLusaDialog
            open={true}
            className={'dont-hover'}
            handleClose={destroy}
        >
            <Typography variant='h6'>{object ? 'Editar interação' : 'Criar interação'}</Typography>
            {
                !loading && 
                <FormGroup
                    fields={fields}
                    onSubmit={submitForm}
                    initialVals={{
                        moment: object ? object.moment : undefined,
                        owner: object ? object.owner : undefined
                    }}

                    disabled={submitting}
                    errors={error}
                    setErrors={setError}

                    sx={{ width: '100%' }}
                />
            }
        </ChaveLusaDialog>
    );
}

export default ClientInteractionForm;