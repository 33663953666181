import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Tooltip,
    Chip,
    Avatar
} from '@mui/material';

import {
    AttachFile as AttachFileIcon,
    LocationOn as LocationOnIcon,
    Event as EventIcon,
    Home as HomeIcon,
    KingBed as KingBedIcon,
    Archive as ArchiveIcon,
    StickyNote2 as StickyNote2Icon,
    Assignment as AssignmentIcon,
    Euro as EuroIcon,
    SyncLock as SyncLockIcon,
    Forum,
    Person
} from '@mui/icons-material'

import {DateHelper, reduceString} from "../../../../utils";
import ClientInteractionWindow from "../Window";
import { USER_ID } from "../../../../../Services/permissions";


const ClientInteractionBanner = ({ object, clientAgent, setWindow, windowChanged, updateElement, openEdit, selected }) => {

    console.log("COMPONENT ClientInteractionBanner", object);
    
    const getWindow = () => {
        return (
            <ClientInteractionWindow 
                object={object}
                clientAgent={clientAgent}
                updateElement={updateElement}
                windowChanged={windowChanged}
            />
        )
    }

    // Handle window
    const open = () => {
        if (!selected) {
            console.log("Opening interaction window...");
            setWindow(getWindow());
        } else {
            setWindow(null);
        }
    }

    // When classification instance is updated, if selected, update window
    // How many days in the future?
    const [days_left, setDays] = useState(undefined); 
    const [hours_left, setHours] = useState(undefined); 
    const [minutes_left, setMinutes] = useState(undefined); 
    useEffect(() => {
        if (selected) {
            setWindow(getWindow());
        }

        setDays(DateHelper.diff_days(new Date(object.moment), new Date()));
        setHours(DateHelper.diff_hours(new Date(object.moment), new Date()));
        setMinutes(DateHelper.diff_minutes(new Date(object.moment), new Date()));

    }, [object]);

    return (
        <Grid
            container
            direction="row"
            className={!selected ? "elementSelectable" : "elementSelectable selected"}
            my={4}
            onClick={open}
        >
            {
                <Grid
                    xs={2}
                    m={0}
                    container
                >
                    <Typography variant="h3" sx={{ my: 'auto', mx: 'auto' }}>
                        <Forum color={object.accomplished ? "secondary" : object.future ? 'primary' : (object.owner===USER_ID ? "error" : "warning")} fontSize="inherit"/> 
                    </Typography>
                </Grid>
            }
            <Grid
                justifyContent="center"
                container
                direction="column"
                p={3}
                xs={10}
            >
                <Typography variant="h6" mb={0}>Interação</Typography>
                <Grid container>
                    <Typography variant="caption" mr={2}>
                        <Tooltip title="Responsável">
                            <Person color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                        </Tooltip>
                        {object.ownerData.user.name}
                    </Typography>
                    <Typography variant="caption" mr={2}>
                        <Tooltip title="Momento">
                            <EventIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                        </Tooltip>
                        {new Date(object.moment).toLocaleDateString('pt-PT', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
                    </Typography>
                </Grid>
                {
                    (object.report) && 
                    <Tooltip 
                        title={<div style={{ whiteSpace: 'pre-line' }}>{object.report}</div>}
                    >
                        <Typography my={2}>
                            <StickyNote2Icon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }}/> 
                            {reduceString(object.report, 75)}
                        </Typography>
                    </Tooltip>
                }
                {
                    !object.accomplished &&
                    <Chip
                        label={object.future ? 
                            `Faltam ${days_left ? days_left : hours_left ? hours_left : minutes_left} ${days_left ? 'dias' : hours_left ? 'horas' : 'minutos'}`
                            : "Interação pendente"
                        }
                        icon={<Forum fontSize="small" />}
                        color={object.future && days_left>1 ? "primary" : (object.owner===USER_ID ? "error" : "warning")}
                        variant={object.future && days_left>1 ? "outlined" : "contained"}
                        sx={{ mt: 3 }}
                    />
                }
            </Grid>
        </Grid>
    );
}

export default ClientInteractionBanner;