import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper
} from '@mui/material';

const ActivityLogTable = ({ log, showModel }) => {
    return (
        <TableContainer component={Paper} sx={{ my: 4 }}>
            <Table sx={{ minWidth: 650 }} aria-label="">
                <TableHead>
                    <TableRow>
                        <TableCell>Momento</TableCell>
                        <TableCell>Utilizador</TableCell>
                        <TableCell>Operação</TableCell>
                        {showModel && <TableCell>Entidade</TableCell>}
                        <TableCell>Atributos alterados</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        log.map(
                            l =>
                                <TableRow key={l.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        {new Date(l.moment).toLocaleDateString('pt-PT', { hour: 'numeric', minute: 'numeric' })}
                                    </TableCell>
                                    <TableCell>{l.user.user.name}</TableCell>
                                    <TableCell>{l.operation_display}</TableCell>
                                    {showModel && <TableCell>{l.translation ? l.translation : l.model_display}</TableCell>}
                                    <TableCell>
                                        {l.attributes.map(a => <>{a.attr.translation ? a.attr.translation : a.attr.name}<br/></>)}
                                    </TableCell>
                                </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ActivityLogTable;