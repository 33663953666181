import React, { useState, useEffect } from "react";

import {
    Chip,
    Grid
} from '@material-ui/core';

import {
    Chat as ChatIcon,
    AssignmentLate as AssignmentLateIcon,
    Assignment as AssignmentIcon,
    Archive as ArchiveIcon,
    SupervisorAccount as SupervisorAccountIcon,
    Event as EventIcon,
    Forum
} from '@mui/icons-material';


const Tags = ({ clientAgent, show_agent }) => {

    const [ancestors, setAncestors] = useState(undefined);

    useEffect(() => {
        setAncestors(clientAgent.ancestors.sort((a, b) => new Date(a.created) < new Date(b.created)));
    }, [clientAgent]);

    return (
        <>
            <Grid container direction="row" mt={2} gap={1}>
                {
                    clientAgent.archived
                        ?
                        <Grid>
                            <Chip
                                label="Arquivado"
                                icon={<ArchiveIcon fontSize="small" />}
                                color="secondary"
                            />
                        </Grid>
                        :
                        <>
                            {
                                clientAgent.classified
                                    ? <Grid>
                                        <Chip
                                            label="Qualificado"
                                            icon={<AssignmentIcon fontSize="small" />}
                                            color="primary"
                                        />
                                    </Grid>
                                    : <Grid>
                                        <Chip
                                            label="Qualificação pendente"
                                            icon={<AssignmentLateIcon fontSize="small" />}
                                            color="warning"
                                        />
                                    </Grid>
                            }
                            {
                                clientAgent.forms_pending &&
                                <Grid>
                                    <Chip
                                        label="Formulário de contacto pendente"
                                        icon={<ChatIcon fontSize="small" />}
                                        color="warning"
                                    />
                                </Grid>
                            }
                            {
                                clientAgent.interactions_pending &&
                                <Grid>
                                    <Chip
                                        label="Interação pendente"
                                        icon={<Forum fontSize="small" />}
                                        color={clientAgent.interactions_pending_user ? "error" : "warning"}
                                    />
                                </Grid>
                            }
                        </>
                }
            </Grid>
            <Grid container direction="row" mt={2} gap={1}>
                <Grid>
                    <Chip
                        label={`Criado a ${new Date(clientAgent.created).toLocaleDateString('pt-PT')}`}
                        icon={<EventIcon fontSize="small" />}
                        color="primary"
                        variant="outlined"
                    />
                </Grid>
                {
                    (show_agent || (ancestors && ancestors.length > 0)) &&
                    <Grid>
                        <Chip
                            label={clientAgent.agent.name}
                            icon={<SupervisorAccountIcon fontSize="small" />}
                            color="primary"
                            variant="outlined"
                        />
                    </Grid>
                }
                {
                    ancestors && ancestors.length > 0 && ancestors.map((ancestor, i) =>
                        <Grid>
                            <Chip
                                label={`${ancestor.agent.name} de ${new Date(ancestor.created).toLocaleDateString('pt-PT')} até ${new Date((i > 0 ? ancestors[i - 1].created : clientAgent.created)).toLocaleDateString('pt-PT')}`}
                                icon={<SupervisorAccountIcon fontSize="small" />}
                                color="secondary"
                                variant="outlined"
                            />
                        </Grid>
                    )
                }
            </Grid>
        </>
    );
}

export default Tags;